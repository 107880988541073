import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "echaiSliderScrollArea", "echaiSlides", "slide", "previousArrow", "nextArrow", "ampplayer", "lightbox" ]

  static values = {
    index: Number,
    defaultSlideId: Number,
    defaultSlide: Number,
    touchStartX: { type: Number, default: 0 },
    touchStartY: { type: Number, default: 0 },
    isSwipeY: { type: Number, default: 0 },
    swipeDir: { type: String },
    deltaY: { type: Number, default: 0 },
    scalingDown: { type: Boolean, default: false },
    scaleVal: { type: Number, default: 1 },
    toggleThresholdPx: { type: Number, default: 60 }
  }

  animateScale(val) {
    if (val < 1 && this.scalingDownValue) {
      this.scaleValValue = this.lerp(this.easeOutQuad(val), 1, 0.95);
      this.lightboxEl.style.transform = `translate3d(0px, ${Math.pow(
        -this.deltaYValue,
        0.6
      )}px, 0) scale3d(${this.scaleValValue}, ${this.scaleValValue}, 1)`;
      requestAnimationFrame(() => this.animateScale((val += 0.05)));
    }
  }

  resetAnimationScale() {
    this.scalingDownValue = false;
    this.scaleValValue = 1;
  }

  resetStyles() {
    this.lightboxEl.style.transform = `translate3d(0, 0, 0) scale3d(1, 1, 1)`;
  }

  initialize() {
    //document.body.querySelector('amp-story-player')
    this.playerEl = this.ampplayerTarget

    if (this.playerEl.isReady) {
      this.playerIsReady();
    } else {
      this.playerEl.addEventListener('ready', () => {
        this.playerIsReady();
      });
    }

    if (this.defaultSlideIdValue != 0) {
      this.playStory(this.findSlide(this.defaultSlideIdValue).dataset["slideshowUrlParam"], this.findSlide(this.defaultSlideIdValue))
    }
  }

  playerIsReady() {
    this.playerEl.addEventListener('amp-story-player-close', this.closePlayer.bind(this))

    this.lightboxEl = this.lightboxTarget // document.body.querySelector('.lightbox')

    const containerPadding =
      parseFloat(getComputedStyle(this.echaiSlidesTarget).paddingLeft) +
      parseFloat(getComputedStyle(this.echaiSlidesTarget).paddingRight)

    const slideMargin = parseFloat(getComputedStyle(this.slideTarget).marginRight)
    this.slideWidth = slideMargin + this.slideTarget.offsetWidth

    this.maxScroll =
      this.echaiSliderScrollAreaTarget.offsetWidth +
      slideMargin -
      containerPadding -
      this.slideTargets.length * this.slideWidth;

    if (this.maxScroll > 0) {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
      this.nextArrowTarget.classList.toggle('visible', !isMobile)

      this.element.classList.toggle('overflow-right', true)
    }
  }

  next() {
    this.indexValue = Math.max(this.maxScroll, this.indexValue - this.slideWidth * 2)
  }

  previous() {
    this.indexValue = Math.min(0, this.indexValue + this.slideWidth * 2)
  }

  play({ params: { url } }) {
    this.playStory(url, event.target)
  }

  playStory(url, slideEle) {
    this.playerEl.show(url, null, {animate: false})
    document.body.classList.toggle('lightbox-open')
    slideEle.classList.add('hidden')
    this.lightboxEl.classList.remove('closed')
    this.resetStyles()
    this.playerEl.play()
  }

  closePlayer() {
    this.playerEl.pause();
    document.body.classList.toggle('lightbox-open', false)
    this.lightboxEl.classList.add('closed')
    this.slideTargets.forEach((slide) => {
      slide.classList.remove('hidden')
    })
  }

  indexValueChanged() {
    this.scrollSlider()

    this.element.classList.toggle('overflow-left', this.indexValue < 0)
    this.element.classList.toggle('overflow-right', this.indexValue > this.maxScroll)

    this.previousArrowTarget.classList.toggle('visible', this.indexValue < 0)
    this.nextArrowTarget.classList.toggle('visible', this.indexValue > this.maxScroll)
  }

  findSlide(slideId) {
    let slideFound = null;
    this.slideTargets.forEach((slide) => {
      if (slide.dataset.id == slideId) {
        slideFound = slide;
      }
    });
    return slideFound;
  }

  scrollSlider() {
    this.slideTargets.forEach((slide) => {
      slide.style['transform'] = `translateX(${this.indexValue}px)`;
    });
  }
}
