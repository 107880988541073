import { Controller } from "stimulus"
import Choices from 'choices.js'

export default class extends Controller {
  static targets = [ "select", "form" ]

  connect() {
    const choice = new Choices(this.selectTarget, {
      removeItemButton: true
    });

     this.selectTarget.choiceControl = choice
  }

  submit() {
    if (this.hasFormTarget) {
      this.formTarget.requestSubmit()
    }
  }
}
