import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "lightbox" ]

  openlightbox() {
    this.lightboxTarget.classList.remove('closed');
  }

  closelightbox() {
    this.lightboxTarget.classList.add('closed');
  }
}
