import { Controller } from "stimulus"
import debounce from "lodash.debounce";
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [ "form", "editor" ]
  static values = { key: String, confirmchanges: { type: Boolean, default: true } }

  connect() {
    this.autosave =  debounce(this.autosave.bind(this), 500);

    this.visitHandler = this.confirmUnsavedChanges.bind(this);
    window.addEventListener("turbolinks:before-visit", this.visitHandler);
    window.addEventListener("beforeunload", this.visitHandler);
  }

  disconnect() {
    window.removeEventListener("turbolinks:before-visit", this.visitHandler);
    window.removeEventListener("beforeunload", this.visitHandler);

    window.sessionStorage.removeItem(this.keyValue);
  }

  confirmUnsavedChanges(event) {
    if (this.confirmchangesValue) {
      const content = window.sessionStorage.getItem(this.keyValue);
      if (!content || content === this.editorTarget.value) {
        return true;
      }

      if (
        event.type == "turbolinks:before-visit" &&
        window.confirm("Unsaved changes. If you navigate they won't be saved.")
      ) {
        return true;
      }

      event.stopPropagation();
      event.preventDefault();
      return false;
    }
  }

  autosave() {
    let content = this.editorTarget.value;

    if (content) {
      window.sessionStorage.setItem(this.keyValue, content);
      this.formTarget.requestSubmit();
    } else {
      window.sessionStorage.removeItem(this.keyValue);
    }
  }

  update(event) {
    let [data, status, xhr] = event.detail
    if (status === 'Created' && typeof data.html !== 'undefined') {
      this.element.innerHTML = data.html
    }
  }
}
