import { Controller } from "stimulus"
import Rails from '@rails/ujs'
import Sortable from 'sortablejs'

export default class extends Controller {
  connect() {
    var sortable = Sortable.create(this.element, {
      direction: 'horizontal',
      ghostClass: 'bg-primary',
      onUpdate: this.dragged.bind(this)
    })
  }

  dragged(event) {
    Rails.ajax({
      url: event.item.dataset.url,
      type: "PATCH",
      data: `echai_asset[position]=${event.newIndex + 1}`
    })
  }
}
